import React from 'react';
import styles from './SearchInput.module.css'
import searchIcon from '../../assets/icons/search2.svg'

function SearchInput() {
    return (
        <div className={styles.SearchContainer}>
            <input className={styles.Input} placeholder="Search students by skill"/>
            <button className={styles.SearchBtn}><img src={searchIcon} className={styles.Icon}/></button>
            <Suggestions show={true}/>
        </div>
    )
}

function Suggestions(props){
    if(!props.show) return null;
    return (<div className={styles.Suggestions}>
        <span>UI</span>
        <span>UX</span>
    </div>)
}

export default SearchInput
