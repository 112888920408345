import React, {useState} from 'react';
import SearchPage from './SearchPage';

const Home = (props)=>{
    return(
        <div>
            <h2 className="StudentNavSectionTitle">Home</h2>
            <div className="StudentNavSectionContent">
                <SearchPage/>
            </div>
        </div>
    )
}

export default Home;
