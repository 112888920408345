import React from 'react'
import styles from './JobProfiles.module.css';

function JobProfiles() {
    return (
        <>
            <h3 className="GradientText">Popular Job Profiles</h3>
            <div className={styles.JobProfilesList}>
                <Tag>Android Developer</Tag>
                <Tag>UI/UX Designer</Tag>
                <Tag>UI Developer</Tag>
                <Tag>UI/UX Designer</Tag>
                <Tag>Android Developer</Tag>
                <Tag>Business Developer Associate</Tag>
                <Tag>UI Developer</Tag>
            </div>
        </>
    )
}

function Tag (props){
    return (<span className={styles.Tag}>
        <span className={styles.TagContent}>
            {props.children}
        </span>
    </span>)
} 


export default JobProfiles
